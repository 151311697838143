import { Flex, Box, VStack, Stack, Link, Heading, Text, useColorModeValue, Image } from '@chakra-ui/react';
import NextLink from 'next/link';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { DateTime } from 'luxon';
import { useAuth } from '../providers/AuthProvider';
import GoogleLoginButton from './Auth/GoogleLoginButton';
import FacebookLoginButton from './Auth/FacebookLoginButton';
import { EmailLoginBox } from './Auth/EmailLoginBox';
import SimpleCardLayout from './Layout/SimpleCardLayout';

export default function Login({ isSignup }) {
  const [cookies, setCookie] = useCookies();
  const { currentUser, setLastLoginAt } = useAuth();

  const router = useRouter();
  useEffect(() => {
    // ログインしているときに、ログイン画面にアクセスするとダッシュボードへリダイレクトする
    async function redirect() {
      if (currentUser) {
        setLastLoginAt();
        await router.push('/dashboard');
      }
    }

    redirect();

    // Cookieの作成
    const now = DateTime.local();
    const expires = now.plus({ days: 90 });
    const cookieExpire = new Date(expires);

    if (router.query.a8 !== undefined) {
      setCookie('a8', router.query.a8, {
        expires: cookieExpire,
      });
    }
    if (router.query.tcs !== undefined) {
      setCookie('tcs', router.query.tcs, {
        expires: cookieExpire,
      });
    }
  }, [currentUser, router, setCookie]);

  const urlParams = {
    a8: router.query.a8 || cookies.a8 || null,
    tcs: router.query.tcs || cookies.tcs || null,
    utm_medium: router.query.utm_medium,
    utm_source: router.query.utm_source,
    utm_campaign: router.query.utm_campaign,
    agent: router.query.agent,
  };

  return (
    <SimpleCardLayout
      header={
        <VStack justifyContent={'center'} alignItems={'center'}>
          <Image src={'/images/common/logo.png'} alt={'Catchy'} w={'300px'} />
          <Text>
            無料版のご利用にクレジットカードは不要です。
            <br />
            勝手に有料プランに移行することもありませんので、ご安心下さい。
          </Text>
        </VStack>
      }
    >
      <Stack align={'center'}>
        <Heading role="heading" fontSize={{ md: '3xl' }}>
          {isSignup ? '会員登録' : 'ログイン'}
        </Heading>
        {router.query.error && (
          <Box pt={6} position="relative">
            <Text color={'red.500'}>ログインに失敗しました。</Text>
          </Box>
        )}
        <Text pt={6} fontSize={'sm'}>
          <NextLink href={'/terms'} passHref>
            <Link color={'blue.600'} target={'_blank'}>
              利用規約
            </Link>
          </NextLink>
          と
          <NextLink href={'/privacy'} passHref>
            <Link color={'blue.600'} target={'_blank'}>
              プライバシーポリシー
            </Link>
          </NextLink>
          に同意して
        </Text>
      </Stack>
      <Stack spacing={4} justify={'center'} alignItems={'center'} mt={8}>
        <GoogleLoginButton urlParams={urlParams} />
        <FacebookLoginButton urlParams={urlParams} />
      </Stack>
      <Box pt={6} position="relative" display="flex" alignItems="center" justifyContent="center">
        <Box position="absolute" w="100%" borderTop="1px" borderColor="gray.200" />
        <Box position="relative" display="flex" justifyContent="center" bgColor="white" px={3} fontSize="sm">
          またはメールアドレスで{isSignup ? 'ユーザー登録を' : 'ログイン'}する
        </Box>
      </Box>
      <EmailLoginBox isSignup={isSignup} urlParams={urlParams} />
    </SimpleCardLayout>
  );
}
