import axios from 'axios';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

export const useEmailAuth = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const onErrorHandler = (response) => {
    setErrors(response.response.data?.errors ?? {});
  };

  const onFinallyHandler = () => {
    setLoading(false);
  };

  const signUp = useCallback((values) => {
    setLoading(true);

    axios
      .post(`${process.env.NEXT_PUBLIC_API_HOST}/register`, values, {
        withCredentials: true,
      })
      .then((result) => {
        router.push(`/verify-email/${result.data.email_hash}`);
      })
      .catch(onErrorHandler)
      .finally(onFinallyHandler);
  }, []);

  const signIn = useCallback((values) => {
    setLoading(true);

    axios
      .post(`${process.env.NEXT_PUBLIC_API_HOST}/login`, values, {
        withCredentials: true,
      })
      .then(() => {
        location.href = '/dashboard';
      })
      .catch(onErrorHandler)
      .finally(onFinallyHandler);
  }, []);

  const verifyEmail = useCallback((values) => {
    setLoading(true);

    axios
      .post(`${process.env.NEXT_PUBLIC_API_HOST}/email/verify/${values.hash}`, values, {
        withCredentials: true,
      })
      .then(() => {
        location.href = '/dashboard';
      })
      .catch(() => {
        setErrors({ code: ['認証コードが正しくないか期限が切れています'] });
      })
      .finally(onFinallyHandler);
  }, []);

  const resendVerifyEmail = useCallback(() => {
    setLoading(true);

    axios
      .post(
        `${process.env.NEXT_PUBLIC_API_HOST}/email/verify/resend`,
        {},
        {
          withCredentials: true,
        }
      )
      .then(() => {
        alert('認証メールを再送しました。');
      })
      .catch(() => {
        setErrors({ code: ['認証メールの再送に失敗しました'] });
      })
      .finally(onFinallyHandler);
  }, []);

  const sendVerifyEmail = useCallback((email) => {
    setLoading(true);

    return new Promise((resolve, error) =>
      axios
        .post(
          `${process.env.NEXT_PUBLIC_API_HOST}/email/verify`,
          { email },
          {
            withCredentials: true,
          }
        )
        .then(() => {
          alert('認証メールを送信しました。');
          setErrors({});
          setLoading(false);
          resolve();
        })
        .catch(({ response }) => {
          setErrors({ code: ['認証メールの送信に失敗しました。', response?.data?.message ?? ''] });
          error();
        })
        .finally(onFinallyHandler)
    );
  }, []);

  const updatePassword = useCallback((values) => {
    setLoading(true);

    return new Promise((resolve, error) =>
      axios
        .put(`${process.env.NEXT_PUBLIC_API_HOST}/user/password`, values, {
          withCredentials: true,
        })
        .then(() => {
          alert('パスワードを更新しました。');
          setErrors({});
          resolve();
        })
        .catch((e) => {
          onErrorHandler(e);
          error();
        })
        .finally(onFinallyHandler)
    );
  }, []);

  /**
   * パスワードリセットメールを送信する
   *
   * 存在するメールアドレスかどうかの判断ができないように、
   * APIレスポンスがエラーであっても、メール送信に成功している扱いでalertを表示する。
   */
  const sendPasswordResetLink = useCallback((values) => {
    setLoading(true);

    return axios.post(`${process.env.NEXT_PUBLIC_API_HOST}/forgot-password`, values).finally(() => {
      alert('パスワードリセットメールを送信しました。受信ボックスを確認してください。');
      onFinallyHandler();
    });
  }, []);

  const resetPassword = useCallback((values) => {
    setLoading(true);

    return axios
      .post(`${process.env.NEXT_PUBLIC_API_HOST}/reset-password`, values)
      .then(() => {
        alert('パスワードをリセットしました。');
        router.push('/login');
      })
      .catch(onErrorHandler)
      .finally(onFinallyHandler);
  }, []);

  return {
    loading,
    signUp,
    signIn,
    verifyEmail,
    resendVerifyEmail,
    sendVerifyEmail,
    updatePassword,
    sendPasswordResetLink,
    resetPassword,
    errors,
  };
};
