import { Button } from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { AiFillFacebook } from 'react-icons/ai';

export default function FaebookLoginButton({ urlParams }) {
  const [isFacebookLoading, setIsFacebookLoading] = useState(false);

  const handleFacebook = () => {
    setIsFacebookLoading(true);
    axios
      .post(`${process.env.NEXT_PUBLIC_API_HOST}/oauth/facebook/redirect`, urlParams, {
        withCredentials: true,
      })
      .then((res) => {
        location.href = res.data.url;
      });
  };

  return (
    <Button
      leftIcon={<AiFillFacebook />}
      size={'md'}
      onClick={handleFacebook}
      isLoading={isFacebookLoading}
      colorScheme={'facebook'}
      w={'100%'}
    >
      Facebook で続ける
    </Button>
  );
}
