import { Button, Image } from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';

export default function GoogleLoginButton({ urlParams }) {
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);

  const handleGoogle = () => {
    setIsGoogleLoading(true);
    axios
      .post(`${process.env.NEXT_PUBLIC_API_HOST}/oauth/google/redirect`, urlParams, {
        withCredentials: true,
      })
      .then((res) => {
        location.href = res.data.url;
      });
  };

  return (
    <Button
      leftIcon={<GoogleIcon />}
      size={'md'}
      onClick={handleGoogle}
      isLoading={isGoogleLoading}
      w={'100%'}
      bg={'gray.200'}
    >
      Google で続ける
    </Button>
  );
}

export const GoogleIcon = () => {
  return <Image src={'/images/common/google.svg'} w={'24px'} alt={'Google ログイン'} />;
};
