import { FormControl, FormLabel, Input, Stack, Link, Button, FormHelperText, FormErrorMessage } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useEmailAuth } from '../../hooks/Auth/useEmailAuth';

export function EmailLoginBox({ isSignup, urlParams }) {
  const { signIn, signUp, errors, loading } = useEmailAuth();

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (isSignup) {
        signUp({
          ...urlParams,
          email: e.target.email.value,
          password: e.target.password.value,
          password_confirmation: e.target.password_confirmation.value,
          name: e.target.name.value,
        });
      } else {
        signIn({
          email: e.target.email.value,
          password: e.target.password.value,
        });
      }
    },
    [isSignup, urlParams]
  );

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4} justify={'center'} alignItems={'center'} mt={8}>
        <FormControl isInvalid={errors?.email !== undefined}>
          <FormLabel fontSize="sm">メールアドレス</FormLabel>
          <Input type="email" name="email" placeholder="user@example.com" />
          {errors?.email &&
            errors.email.map((error, i) => (
              <FormErrorMessage align="left" key={i}>
                {error}
              </FormErrorMessage>
            ))}
        </FormControl>
        <FormControl isInvalid={errors?.password !== undefined}>
          <FormLabel fontSize="sm">パスワード</FormLabel>
          <Input type="password" name="password" />
          <FormHelperText>半角英数字大文字小文字を全て含めて8文字以上で設定してください</FormHelperText>
          {errors?.password &&
            errors.password.map((error, i) => (
              <FormErrorMessage align="left" key={i}>
                {error}
              </FormErrorMessage>
            ))}
        </FormControl>
        {!isSignup && (
          <Link href="/forgot-password" textAlign="left" fontSize="sm" color="blue.600">
            パスワードを忘れた場合
          </Link>
        )}
        {isSignup && (
          <FormControl isInvalid={errors?.password_confirmation !== undefined}>
            <FormLabel fontSize="sm">パスワード確認</FormLabel>
            <Input type="password" name="password_confirmation" />
            <FormHelperText>確認のためにパスワードをもう一度入力してください</FormHelperText>
            {errors?.password_confirmation &&
              errors.password_confirmation.map((error, i) => (
                <FormErrorMessage align="left" key={i}>
                  {error}
                </FormErrorMessage>
              ))}
          </FormControl>
        )}
        <FormControl>
          <Button w="100%" isLoading={loading} type="submit">
            {isSignup ? 'ユーザー登録' : 'ログイン'}
          </Button>
          <FormHelperText>
            {isSignup ? (
              <Link href="/login" color={'blue.600'}>
                ログインはこちらから
              </Link>
            ) : (
              <Link href="/signup" color={'blue.600'}>
                ユーザー登録はこちらから
              </Link>
            )}
          </FormHelperText>
        </FormControl>
      </Stack>
    </form>
  );
}
