import { Box, Flex, Image, Stack, Text, useColorModeValue, VStack } from '@chakra-ui/react';

function SimpleCardLayout({ header, children }) {
  return (
    <Flex minH={'100vh'} align={'top'} justify={'center'} bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} p={12} textAlign={'center'}>
        {header}
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          px={{ sm: 8, md: 20 }}
          py={8}
          textAlign={'center'}
        >
          {children}
        </Box>
      </Stack>
    </Flex>
  );
}

export default SimpleCardLayout;
